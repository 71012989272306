export const environment = {
  local: false,
  production: true,
  parseAPPID: 'cZdZP9LFErufKhdHzkSvUkFHmjpJ5hZ2LiphqXY8',
  parseJSKey: 's4mgz2k48hSZNWDHkULPF5pbc5ZnyZYDSAaj26uF',
  parseServerURL: 'https://parse.prosafecon.de/parse',
  parseUserClass: {
    user: 'User',
    _user: '_User',
  },
  parseRoleClass: '_Role',
  features: {
    certificate: true,
    notes: true,
    contract: true,
    annualReport: true,
    statistics: true,
    map: true,
    protocol: true,
    legacyProtocol: false,
    library: true,
    paymentPeriod: false,
    accountingRange: true
  }
};
